import React from "react";
import nursery from "./assets/nursery.jpg";
import ultrasound from "./assets/ultrasound.jpg";

function Baby() {
  return (
    <div className="App-baby">
      <h1>Baby Shower </h1>
      {/* <h5>
        March 26, 2023
       
        145 Hearthstone Drive, Bartlett IL 60103
      </h5> */}

      <p>
        Join us for a baby shower for our daughter! We are excited to celebrate
        with everyone. We'll have lunch, activities, and a cloth diaper raffle;
        bring a cloth diaper to enter the raffle for a prize!
      </p>
      <p>
        If you want to give a gift to help us prepare for our newest family
        addition, that would be very kind and we'd be super grateful. We put
        together a few registries of all the things we need.
      </p>

      <p>
        To enter the diaper raffle, we'd love if you could help stock us with
        cloth diapers! You can get one from here.
      </p>
      <a
        className="App-link"
        href="https://www.myregistry.com/wishlist/sarah-cepiel-bartlett-il/3622649/giftlist"
        target="_blank"
        rel="noopener noreferrer"
      >
        Cloth Diaper Raffle
      </a>
      <p>If you like shopping online </p>
      <a
        className="App-link"
        href="https://www.amazon.com/baby-reg/lucas-cepiel-june-2023-bartlett/1V7FW48DAG7ON"
        target="_blank"
        rel="noopener noreferrer"
      >
        Amazon
      </a>
      <br />
      <a
        className="App-link"
        href="https://www.babylist.com/cepiel-2023"
        target="_blank"
        rel="noopener noreferrer"
      >
        Babylist
      </a>
      <br />
      <p>If you like shopping in stores </p>
      <a
        className="App-link"
        href="https://www.buybuybaby.com/store/giftregistry/viewregistryguest/552145785?eventType=Baby"
        target="_blank"
        rel="noopener noreferrer"
      >
        BuyBuyBaby
      </a>

      <br />
      <br />

      <h5>The nursery is really coming together!</h5>
      <img src={nursery} className="nursery-pic" alt="" />
      <br />
      <h5>20 week ultrasound </h5>
      <img src={ultrasound} className="App-baby-pic" alt="" />
    </div>
  );
}

export default Baby;
