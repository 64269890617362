import React from "react";

function Header() {
  return (
    <div className="App-header">
      <div class="row">
        <p>The Cepiels</p>
      </div>
    </div>
  );
}

export default Header;
