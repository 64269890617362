import Header from "./components/Header.js";
import Baby from "./Baby.js";
import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import leftBorder from "./assets/leftleaves.png";
import rightBorder from "./assets/rightleaves.png";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <div className="App-all">
          <Header />

          <img class="top-right" src={rightBorder} alt="border right" />

          <Routes>
            {/* <Route exact path="/baby" element={<Baby />} /> */}
            {/* <Route path="*" element={<Home />} /> */}
            <Route path="*" element={<Baby />} />
          </Routes>

          {/* <Footer /> */}
        </div>
      </div>
      <img class="bottom-left" src={leftBorder} alt="border left" />
    </BrowserRouter>
  );
}

export default App;
